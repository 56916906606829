<template>
  <div class="stock-store-availability">
    <div v-if="currentStore" :class="{ 'availability available': isAvailable, 'availability not-available': !isAvailable }">
      <strong v-if="isAvailable && qtyPickup > 0">
        <span v-if="qtyPickup <= thresholdQty">
          <span v-if="qtyPickup < 5">nur </span><strong class="qty">{{ qtyPickup }}</strong>
        </span> verfügbar
      </strong>
      <strong v-else-if="isAvailable"><span class="hide">abholbar</span> in 10 Tagen</strong>
      <strong v-else-if="!isAvailable">nicht verfügbar</strong>
      <span>in</span>
      <a
        @click="selectStore"
        v-if="showModalButton"
      >
        {{ currentStore.name || 'Store wählen' }}
      </a>
      <span v-else>
        {{ currentStore.name }}
      </span>
    </div>
    <div class="availability not-selected" v-else-if="showNotSelected">
      <a @click="selectStore">
        <span class="hide">Meinen</span> Markt wählen
      </a>
    </div>
  </div>
</template>

<script>
import Availability from '../mixins/Availability'
import StoreSelector from '../mixins/StoreSelector'

export default {
  mixins: [ Availability, StoreSelector ],
  props: {
    store: {
      type: Object,
      default: () => {
        return {}
      }
    },
    product: {
      type: Object,
      default: () => {
        return {}
      }
    },
    showModalButton: {
      type: Boolean,
      default: false
    },
    showNotSelected: {
      type: Boolean,
      default: false
    },
    qtyPickup: {
      type: Number,
      default: null
    }
  },
  watch: {
    selectedStore (store) {
      if (!this.useStoreProp() && store) {
        this.currentStore = store
      }
    }
  },
  computed: {
    isAvailable () {
      if (this.qtyPickup === null) {
        return false
      }
      return (
        (this.qty && this.qty > 0) ||
        (this.qtyPickup && this.qtyPickup > 0)
      )
    }
  },
  async mounted () {
    this.currentStore = (this.useStoreProp()) ? this.store : this.selectedStore
  },
  methods: {
    useStoreProp () {
      // check if this.store is empty (https://stackoverflow.com/questions/679915/how-do-i-test-for-an-empty-javascript-object)
      return !(this.store && Object.keys(this.store).length === 0 && this.store.constructor === Object)
    }
  }
}
</script>
