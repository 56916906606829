<template>
  <div class="sf-product-card" v-if="type != 'virtual'">
    <div class="sf-product-card__image-wrapper">
      <slot name="image" v-bind="{ image, title }">
        <SfLink v-if="gallery.length > 0" class="sf-product-card__link" :link="link">
          <SfImage
            v-for="(item, i) in gallery.slice(0, 2)" :key="i"
            class="sf-product-card__image gallery"
            :src="item.src"
            :alt="''"
            :width="imageWidth"
            :height="imageHeight"
          />
        </SfLink>

        <SfLink v-else class="sf-product-card__link" :link="link">
          <template v-if="Array.isArray(image)">
            <SfImage
              v-for="(picture, key) in image.slice(0, 2)"
              :key="key"
              class="sf-product-card__picture"
              :src="picture"
              :alt="title"
              :width="imageWidth"
              :height="imageHeight"
            />
          </template>
          <SfImage
            v-else
            class="sf-product-card__image"
            :src="image"
            :alt="title"
            :width="imageWidth"
            :height="imageHeight"
          />
        </SfLink>
      </slot>
      <div class="badges">
        <div class="badges-top-right">
          <div class="badge badge-special" v-if="specialPrice && !isNaN(discountPercentage(specialPrice, regularPrice))">
            -{{ discountPercentage(specialPrice, regularPrice) }}%
          </div>          
        </div>
        <div class="badges-top-left">
          <div v-for="badge in badges.filter(o => !['483', '484', '778','934'].includes(o.value))" :key="badge.value" :class="'badge badge-' + badge.value">
            <span>{{ badge.label }}</span>
          </div>
        </div>
        <div class="badges-bottom-right">
          <div v-for="badge in badges.filter(o => ['483', '484'].includes(o.value))" :key="badge.value" :class="'badge badge-' + badge.value">
            <span>{{ badge.label }}</span>
          </div>
        </div>
        <div class="badges-bottom-left">
          <div v-for="badge in badges.filter(o => ['934', '778'].includes(o.value))" :key="badge.value" :class="'badge badge-' + badge.value">
          </div>
        </div>
      </div>

      <slot name="badge" v-bind="{ badgeLabel, badgeColor }">
        <SfBadge
          v-if="badgeLabel"
          class="sf-product-card__badge"
          :class="badgeColorClass"
        >{{ badgeLabel }}</SfBadge>
      </slot>

      <template v-if="showAddToCartButton">
        <slot
          name="add-to-cart"
          v-bind="{
            isAddedToCart,
            showAddedToCartBadge,
            isAddingToCart,
            title,
          }"
        >
          <SfCircleIcon
            class="sf-product-card__add-button"
            :aria-label="`Add to Cart ${title}`"
            :has-badge="showAddedToCartBadge"
            :disabled="addToCartDisabled"
            @click="onAddToCart"
          >
            <div class="sf-product-card__add-button--icons">
              <transition
                name="sf-product-card__add-button--icons"
                mode="out-in"
              >
                <slot v-if="!isAddingToCart" name="add-to-cart-icon">
                  <SfIcon
                    key="add_to_cart"
                    icon="add_to_cart"
                    size="20px"
                    color="white"
                  />
                </slot>
                <slot v-else name="adding-to-cart-icon">
                  <SfIcon
                    key="added_to_cart"
                    icon="added_to_cart"
                    size="20px"
                    color="white"
                  />
                </slot>
              </transition>
            </div>
          </SfCircleIcon>
        </slot>
      </template>
    </div>

    <SfLink class="sf-product-card__link" :link="link">
      <slot name="title" v-bind="{ title }">
        <h3 class="sf-product-card__title">
          {{ title }}
        </h3>
        <div
          v-for="badge in badges.filter(o => ['778'].includes(o.value))"
          :key="badge.value"
          class="sf-product-card__subtitle"
        >
          Set inkl. Fussleiste und Dämmung
        </div>
        <div
          v-for="badge in badges.filter(o => ['934'].includes(o.value))"
          :key="badge.value"
          class="sf-product-card__subtitle"
        >
          Set inkl. Fussleiste
        </div>
      </slot>
    </SfLink>

    <AProductRating
      :reviews="reviews"
      v-if="reviews.length > 0"
    >
      {{ $t('Leave a review') }}
    </AProductRating>

    <div
      class="sf-product-card__description"
      v-if="description"
      v-html="description"
    >
    </div>

    <SfButton
      v-if="wishlistIcon !== false"
      :aria-label="`${ariaLabel} ${title}`"
      :class="wishlistIconClasses"
      @click="toggleIsOnWishlist"
    >
      <slot name="wishlist-icon" v-bind="{ currentWishlistIcon }">
        <SfIcon
          :icon="currentWishlistIcon"
          size="22px"
          data-test="sf-wishlist-icon"
        />
      </slot>
    </SfButton>
    <a-product-base-price
      :min="minPrice"
      :regular="regularPrice"
      :special="specialPrice"
      :factor="basePrice.factor"
      :unit="basePrice.unit"
      v-if="basePrice.unit"
    />
    <slot name="price" v-bind="{ specialPrice, regularPrice, minPrice }">
      <div class="sf-product-card__price" v-if="basePrice.factor == 1">
        <div class="unit" v-if="sellingUnit && basePrice.unit">pro <a-product-unit :product="{sku: stock.sku}" :unit="sellingUnit" :qty="1" /></div>
        <span
          class="price-min"
          :class="{ 'regular-available' : regularPrice }"
          v-if="(!specialPrice && specialPrice !== 0) && minPrice"
        >ab {{ formatPrice(minPrice) }}</span>
        <SfPrice
          v-if="regularPrice"
          :class="[ { 'min-available' : (!specialPrice && specialPrice !== 0) && minPrice }, basePrice.unit ? '' : 'default' ]"
          :regular="formatPrice(regularPrice)"
          :special="specialPrice ? formatPrice(specialPrice) : ''"
        />
      </div>
      <span
        v-if="regularPrice && specialPrice"
        class="sf-product-card__savings"
      ><span>{{ formatPrice((regularPrice - specialPrice) / basePrice.factor) }}<span v-if="basePrice.unit">/{{basePriceUnit(basePrice.unit)}}</span> gespart</span>
      </span>
    </slot>

    <div class="sf-product-card__action">
      <div class="product-availability">
        <OnlineAvailability
          :qty="stock.qty"
          :threshold-qty="stock.thresholdQuantity"
        />

        <StoreAvailability
          :show-modal-button="true"
          :qty="stock.qty"
          :qty-pickup="stock.qtyPickup"
          :threshold-qty="stock.thresholdQuantity"
        />
      </div>

      <SfLink class="sf-product-card__link btn btn-arrow btn-sm arrow-right to-product" :link="link" />
    </div>
  </div>
  <div class="sf-product-card virtual" v-else-if="type == 'virtual'">
    <h3>{{ title }}</h3>
    <div class="description" v-html="description"></div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import {
  SfIcon,
  SfLink,
  SfPrice,
  SfRating,
  SfImage,
  SfCircleIcon,
  SfBadge,
  SfButton,
  SfCarousel
} from '@storefront-ui/vue';
import StoreAvailability from 'src/modules/vsf-inventory-sources/components/StoreAvailability'
import OnlineAvailability from 'src/modules/vsf-inventory-sources/components/OnlineAvailability';
import AProductBasePrice from 'theme/components/atoms/a-product-base-price';
import { price } from '@vue-storefront/core/filters';
import AProductUnit from 'theme/components/atoms/a-product-unit'
import { discountPercentage, basePriceUnit } from 'theme/helpers/custom';
import AProductRating from 'theme/components/atoms/a-product-rating';

export default {
  name: "SfProductCard",
  components: {
    SfPrice,
    SfRating,
    SfIcon,
    SfImage,
    SfLink,
    SfCircleIcon,
    SfBadge,
    SfButton,
    StoreAvailability,
    OnlineAvailability,
    AProductBasePrice,
    AProductUnit,
    SfCarousel,
    AProductRating
  },
  props: {
    sku: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: ''
    },
    stock: {
      type: Object,
      default: () => ({})
    },
    /**
     * Product image
     * It should be an url of the product
     */
    image: {
      type: [Array, Object, String],
      default: "",
    },
    /**
     * Product image width, without unit
     */
    imageWidth: {
      type: [String, Number],
      default: 600,
    },
    /**
     * Product image height, without unit
     */
    imageHeight: {
      type: [String, Number],
      default: 600,
    },
    /**
     * Badge label
     */
    badgeLabel: {
      type: String,
      default: "",
    },
    /**
     * Badge color
     * It can be according to our standard colors, or legitimate CSS color such as `#fff`, `rgb(255,255,255)`), and `lightgray` or nothing.
     * Standard colors: `primary`, `secondary`, `white`, `black`, `accent`, `green-primary`, `green-secondary`, `gray-primary`, `gray-secondary`, `light-primary`, `light-secondary`, `pink-primary`, `pink-secondary`, `yellow-primary`, `yellow-secondary`, `blue-primary`, `blue-secondary`.
     */
    badgeColor: {
      type: String,
      default: "",
    },
    /**
     * Product title
     */
    title: {
      type: String,
      default: "",
    },
    /**
     * Link to product page
     */
    link: {
      type: [String, Object],
      default: "",
    },
    description: {
      type: String,
      default: ""
    },
    /**
     * Link element tag
     * By default it'll be 'router-link' if Vue Router
     * is available on instance, or 'a' otherwise.
     * @deprecated will be removed in 1.0.0 use SfLink instead
     */
    linkTag: {
      type: String,
      default: undefined,
    },
    /**
     * Product rating
     */
    scoreRating: {
      type: [Number, Boolean],
      default: false,
    },
    /**
     * Product reviews count
     */
    reviewsCount: {
      type: [Number, Boolean],
      default: false,
    },
    reviews: {
      type: Array,
      default: () => []
    },
    /**
     * Maximum product rating
     */
    maxRating: {
      type: [Number, String],
      default: 5,
    },
    minPrice: {
      type: [Number, String],
      default: null
    },
    regularPrice: {
      type: [Number, String],
      default: null
    },
    specialPrice: {
      type: [Number, String],
      default: null
    },
    basePrice: {
      type: Object,
      default: () => {}
    },
    sellingUnit: {
      type: Number,
      default: null
    },
    /**
     * Wish list icon
     * This is the default icon for product not yet added to wish list.
     * It can be a icon name from our icons list, or array or string as SVG path(s).
     */
    wishlistIcon: {
      type: [String, Array, Boolean],
      default: "heart",
    },
    /**
     * Wish list icon for product which has been added to wish list
     * This is the icon for product added to wish list. Default visible on mobile. Visible only on hover on desktop.
     * It can be a icon name from our icons list, or array or string as SVG path(s).
     */
    isOnWishlistIcon: {
      type: [String, Array],
      default: "heart_fill",
    },
    /**
     * Status of whether product is on wish list or not
     */
    isOnWishlist: {
      type: Boolean,
      default: false,
    },
    /**
     * Status of showing add to cart button
     */
    showAddToCartButton: {
      type: Boolean,
      default: false,
    },
    /**
     * isAddedToCart status of whether button is showed, product is added or not
     */
    isAddedToCart: {
      type: Boolean,
      default: false,
    },
    /**
     * addToCartDisabled status of whether button is disabled when out of stock
     */
    addToCartDisabled: {
      type: Boolean,
      default: false,
    },
    badges: {
      type: Array,
      default: () => []
    },
    bundleOptions: {
      type: Array,
      default: () => []
    },
    gallery: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      isAddingToCart: false,
    };
  },
  computed: {
    ...mapGetters({
      getCurrentCustomOptions: 'product/getCurrentCustomOptions'
    }),
    isSFColors() {
      return false // SF_COLORS.includes(this.badgeColor.trim());
    },
    badgeColorClass() {
      return this.isSFColors ? `${this.badgeColor.trim()}` : "";
    },
    currentWishlistIcon() {
      return this.isOnWishlist ? this.isOnWishlistIcon : this.wishlistIcon;
    },
    showAddedToCartBadge() {
      return !this.isAddingToCart && this.isAddedToCart;
    },
    ariaLabel() {
      return this.isOnWishlist ? "Remove from wishlist" : "Add to wishlist";
    },
    wishlistIconClasses() {
      const defaultClass = "sf-button--pure sf-product-card__wishlist-icon";
      return `${defaultClass} ${
        this.isOnWishlist ? "sf-product-card--on-wishlist" : ""
      }`;
    },
    linkComponentTag() {
      /*
      deprecationWarning(
        this.$options.name,
        "Prop 'linkTag' has been deprecated and will be removed in v1.0.0. Use 'SfLink' instead."
      );
      */
      if (this.linkTag) {
        return this.linkTag;
      }
      if (this.link) {
        return typeof this.link === "object" || this.$router
          ? "router-link"
          : "a";
      }
      return "div";
    },
  },
  methods: {
    formatPrice (rawPrice) {
      return price(rawPrice)
    },
    discountPercentage (price, oldPrice) {
      return discountPercentage(price, oldPrice)
    },
    basePriceUnit (unit) {
      return basePriceUnit(unit)
    },
    toggleIsOnWishlist() {
      this.$emit("click:wishlist", !this.isOnWishlist);
    },
    onAddToCart(event) {
      event.preventDefault();
      this.isAddingToCart = true;
      setTimeout(() => {
        this.isAddingToCart = false;
      }, 1000);
      this.$emit("click:add-to-cart");
    },
  }
};
</script>

<style lang="scss">
@import "~@storefront-ui/shared/styles/components/organisms/SfProductCard.scss";
</style>
