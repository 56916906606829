<template>
  <div class="stock-online-availability">
    <div :class="{ 'availability available': isAvailable, 'availability not-available': !isAvailable, 'store-selected': selectedStore }">
      <strong v-if="isAvailable">
        <span v-if="product.restmenge && qty <= thresholdQty">
          <span v-if="qty < 5">nur </span><strong class="qty">{{ qty }}</strong>
        </span> 
        verfügbar <span class="hide">online</span>
      </strong>
      <strong v-if="!isAvailable"></span class="hide">online</span> nicht verfügbar</strong>
    </div>
    <div class="delivery-time" v-if="showDeliveryTime"><strong>{{ deliveryTime }}</strong></div>
  </div>
</template>

<script>
import Availability from '../mixins/Availability'

export default {
  mixins: [ Availability ],
  props: {
    showDeliveryTime: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    deliveryTime () {
      var deliveryTime = this.product.delivery_time
      if (this.product.type_id === 'bundle') {
        deliveryTime = this.product.bundle_options.find(p => true)?.product_links.find(p => true)?.product.delivery_time
      }
      return deliveryTime || 'keine Lieferzeit angegeben'
    }
  }
}
</script>
