<template>
  <div class="a-product-rating" @click="$emit('click')">
    <div class="product__rating">
      <SfRating :score="score" :max="max" />
      <div class="score">
        <span class="value">{{ score }}</span>
        <span class="max">aus {{ max }}</span>
      </div>
      <div class="count">{{ reviews.length }} {{ $t('Reviews') }}</div>
    </div>
    <div v-if="!!reviews" class="product__count">
      Basierend auf {{ reviews.length }} Bewertungen
    </div>
    <div class="product__score-counts">
      <div
        v-for="index in max"
        :key="index"
        class="bar"
        :style="{ '--width': width(index) + '%' }"
      >
        <div class="text">{{ index }} <span v-if="index === 1">Stern</span><span v-else>Sterne</span></div>
        <div class="inner"><!--{{ countScore(index) }}/{{ max }}--></div>
      </div>
    </div>
    <SfButton class="sf-button--text">
      <slot />
    </SfButton>
  </div>
</template>

<script>
import { SfRating, SfButton } from '@storefront-ui/vue';
export default {
  components: {
    SfRating,
    SfButton
  },
  props: {
    reviews: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      max: 5
    }
  },
  methods: {
    countScore (score) {
      return this.reviews.filter(review => (review.rating - score) < 1 && (review.rating - score) >= 0).length
    },
    width (index) {
      if ( this.count === 0 ) {
        return 0
      }

      return this.countScore(index)/this.count*100
    }
  },
  computed: {
    score () {
      const allScore = this.reviews.reduce((sum, rev) => (sum + (rev.rating || 0)), 0)
      return parseFloat((Math.round((allScore ? allScore / this.count : allScore) * 10) / 10).toFixed(1))
    },
    count () {
      return this.reviews.length
    }
  }
};
</script>

<style lang="scss" scoped>
.product {
  &__rating {
    display: flex;
    align-items: center;
    margin: var(--spacer-xs) 0 0 0;
  }
  &__count {
    color: var(--c-text);
    text-decoration: none;
    margin: 0 0 0 var(--spacer-xs);
  }
}
</style>
